import {useTheme} from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const useIsSmallMobile = () => {
  const {breakpoints} = useTheme()

  return useMediaQuery(breakpoints.down('xs'))
}

export default useIsSmallMobile
