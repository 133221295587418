import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette}) => ({
  cardGrid: {
    padding: 0,
  },
  card: {
    boxShadow: 'none',
    cursor: 'pointer',
  },
  cardInactive: {
    boxShadow: 'none',
  },
  kmCard: {
    boxShadow: 'none',
    cursor: 'pointer',
    background: palette.background.greyLight,
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      background: palette.background.contrastGrey,
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 26,
    fontSize: 20,
  },
  logo: {
    width: 140,
    height: 84,
    objectFit: 'contain',
  },
}))

export default useStyles
