import Container from '@mui/material/Container'
import React from 'react'

import {Link} from 'gatsby'
import {technologyPartnersHeaderPropTypes} from 'helpers/propTypes'
import Description from 'components/UI/Description'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useIsMobile from 'hooks/useIsMobile'
import useStyles from './styles'

const TechnologyPartnersHeader = ({
  pageTitle,
  description,
  eulaCallToAction,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  return (
    <Section hasPaddingBottom={false} hasVerySmallPadding>
      <Title variant="h1" type="title" isCentered={!!isMobile}>
        {pageTitle}
      </Title>
      <Container className={classes.descriptionContainer}>
        <Description type="largeDescription">{description}</Description>
        {process.env.GATSBY_WEBSITE === 'keyrus' && (
          <Link to="/EULA">{eulaCallToAction}</Link>
        )}
      </Container>
    </Section>
  )
}

TechnologyPartnersHeader.propTypes = technologyPartnersHeaderPropTypes

TechnologyPartnersHeader.defaultProps = {
  description: null,
  eulaCallToAction: null,
  pageTitle: null,
}

export default TechnologyPartnersHeader
