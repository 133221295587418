import * as R from 'ramda'
import {Card, CardContent, Container, Grid} from '@mui/material'
import React from 'react'

import {createHash, createKlsHash} from 'helpers/utils'
import {partnersListPropTypes} from 'helpers/propTypes'

import useIsMobile from 'hooks/useIsMobile'
import useStyles from './styles'

const Partners = ({
  partners,
  selectedPartner,
  setSelectedPartner,
  hasPartnersName,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  const handleClick = partner => {
    if (selectedPartner) {
      window.location.hash = window.location.hash.replace(
        `#/${selectedPartner.name}`,
        '',
      )
    }
    if (process.env.GATSBY_WEBSITE === 'keyrus') {
      createHash(partner.name)
    }
    if (
      process.env.GATSBY_WEBSITE === 'kls' ||
      process.env.GATSBY_WEBSITE === 'keyrus-management'
    ) {
      createKlsHash(partner.name)
    }
    document.querySelector(':root').style.scrollBehavior = 'auto'
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    })
    document.querySelector(':root').style.scrollBehavior = 'smooth'
    setSelectedPartner(partner)
  }

  const keyrusManagementCondition = () =>
    process.env.GATSBY_WEBSITE === 'keyrus-management'

  const conditionToRenderCardClasses = () => {
    if (process.env.GATSBY_WEBSITE === 'keyrus-management') {
      return classes.cardInactive
    }

    return classes.card
  }

  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <Grid
        container
        spacing={4}
        style={{margin: 0, width: isMobile && '100%'}}
      >
        {R.map(
          partner => (
            <Grid
              item
              key={partner.name}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              style={{
                paddingLeft: !isMobile && 0,
                paddingRight: !isMobile && 24,
              }}
            >
              <Card
                className={conditionToRenderCardClasses()}
                onClick={() =>
                  !keyrusManagementCondition() && handleClick(partner)
                }
              >
                <CardContent className={classes.cardContent}>
                  <img
                    src={partner.logo.file.url}
                    alt={partner.name}
                    loading="lazy"
                    className={classes.logo}
                  />
                  {hasPartnersName && (
                    <p className={classes.partnerTitle}>{partner.name}</p>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ),
          partners,
        )}
      </Grid>
    </Container>
  )
}

Partners.propTypes = partnersListPropTypes

Partners.defaultProps = {
  hasPartnersName: null,
  partners: null,
  selectedPartner: null,
  setSelectedPartner: null,
}

export default Partners
